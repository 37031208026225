import React, { ChangeEvent, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// @ts-ignore
import ReCAPTCHA from 'react-google-recaptcha';

import styled, { css } from 'styled-components';
import { Button, CheckBox, Icon, IconNames, Input } from 'atoms';
import { Colors } from 'context';
import { tablet, isLearnITTheme } from 'services/ui';
import fontService, { TextType } from 'services/FontService';
import { PATHS } from 'router/config';

import i18n from '../../../i18n';

export interface ISignUpForm {
  values: ISignUpFormValues;
  errors?:
    | {
        [keys in keyof ISignUpErrorValues]?: string;
      }
    | null;
  onFirstNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onPhoneChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onEmailChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onHasAcceptedPrivacyPolicyChange: () => void;
  onRegisterClick: (recaptchaToken: string) => void;
  disableRegister: boolean;
  isLoading: boolean;
  refreshRecaptcha: boolean;
}

export interface ISignUpFormValues {
  firstName: string;
  phone: string;
  email: string;
  hasAcceptedPrivacyPolicy: boolean;
}

export interface ISignUpErrorValues {
  firstName?: string;
  phone?: string;
  email?: string;
  general?: string;
}

const SITE_KEY = process.env.REACT_APP_RECAPTCHA || '';

export const SignUpFormComponent = memo(
  ({
    values,
    errors,
    onFirstNameChange,
    onPhoneChange,
    onEmailChange,
    onRegisterClick,
    onHasAcceptedPrivacyPolicyChange,
    disableRegister,
    isLoading,
    refreshRecaptcha,
  }: ISignUpForm) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const handleRecaptchaChange = useCallback((token: string | null) => {
      setRecaptchaToken(token);
    }, []);

    const handleRegisterClick = useCallback(() => {
      if (recaptchaToken) {
        onRegisterClick(recaptchaToken);
      }
    }, [recaptchaToken, onRegisterClick]);

    useEffect(() => {
      if (refreshRecaptcha) {
        recaptchaRef?.current?.reset();
        setRecaptchaToken(null);
      }
    }, [refreshRecaptcha, recaptchaRef]);

    return (
      <Wrapper>
        <LogoWrapper>
          <Logo name={IconNames.LogoVertical} color={Colors.white} />
        </LogoWrapper>

        <Content>
          <InputStyled
            type="text"
            placeholder={t('login.firstName')}
            value={values.firstName}
            onChange={onFirstNameChange}
          />
          <InputStyled
            type="text"
            placeholder={t('login.phone')}
            value={values.phone}
            onChange={onPhoneChange}
            hasError={Boolean(errors?.phone)}
            message={errors?.phone}
          />
          <InputStyled
            type="email"
            placeholder={t('login.email')}
            value={values.email}
            onChange={onEmailChange}
            hasError={Boolean(errors?.email)}
            message={errors?.email}
          />
          <CheckBoxContent>
            <CheckBoxStyled
              key="hasAcceptedPrivacyPolicy"
              checked={Boolean(values.hasAcceptedPrivacyPolicy)}
              text=""
              onClick={() => onHasAcceptedPrivacyPolicyChange()}
              disabled={false}
            />
            <div>
              Wyrażam zgodę na{' '}
              <Link href="https://learnit.com.pl/data-processing" target="_blank" rel="noreferrer">
                Politykę przetwarzania danych osobowych
              </Link>{' '}
              i wyrażam zgodę na ich przetwarzanie i przechowywanie.
            </div>
          </CheckBoxContent>

          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={SITE_KEY}
            onChange={handleRecaptchaChange}
            hl={i18n.language}
          />

          {errors?.general && <ErrorText>{t('error.error.heading')}</ErrorText>}

          <RegisterButton
            isLoading={isLoading}
            onClick={handleRegisterClick}
            disabled={disableRegister || !recaptchaToken}>
            {t('login.signUp')}
          </RegisterButton>
        </Content>

        <SupportWrapper>
          <SupportTextWrapper onClick={() => navigate(PATHS.login)}>
            <SupportText>{t('login.login')}</SupportText>
          </SupportTextWrapper>
        </SupportWrapper>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  position: relative;
  width: 335px;
  border: 1px solid ${Colors.gray};
  border-radius: 24px;
  box-shadow: 0px 40px 80px #c0c6cb;
  background-color: ${Colors.white};

  ${tablet} {
    width: 432px;
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: -1px;
  left: 20px;
  padding: 36px 10px 24px;
  border-radius: 0 0 8px 8px;
  background-color: ${Colors.black};

  ${tablet} {
    left: 55px;
    padding: 48px 12px 32px;
  }

  ${() =>
    isLearnITTheme() &&
    css`
      top: 58px;
      padding: 0;
      border: 0;
      border-radius: 0;
      background-color: transparent;
      ${tablet} {
        padding: 0;
        top: 67px;
      }
    `}
`;

const Logo = styled(Icon)`
  height: 38px;
  width: 68px;

  ${tablet} {
    height: 48px;
    width: 78px;
  }

  ${() =>
    isLearnITTheme() &&
    css`
      height: 28px;
      width: 160px;
      ${tablet} {
        height: 28px;
        width: 178px;
      }
    `}
`;

const Content = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 128px 20px 30px;

  ${tablet} {
    padding: 160px 55px 34px;
  }
`;

const InputStyled = styled(Input)`
  width: 100%;
  margin-bottom: 16px;
`;

const ErrorText = styled.div`
  color: ${Colors.signal_red};
  font-size: 14px;
  margin-bottom: 8px;
`;

const RegisterButton = styled(Button)`
  margin-top: 16px;
`;

const SupportWrapper = styled.div`
  position: absolute;
  top: calc(100% + 24px);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const SupportTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  opacity: 0.3;
  cursor: pointer;

  &:hover {
    opacity: 1;
    transition: 0.5s;
  }
`;

const SupportText = styled.span`
  margin-left: 5px;
  ${fontService.text({ type: TextType.normal })};
  color: ${Colors.black};
`;

const CheckBoxStyled = styled(CheckBox)`
  // margin-top: 10px;
  width: fit-content;
`;

const CheckBoxContent = styled.div`
  display: flex;
  font-size: 14px;
  gap: 20px;
  margin-bottom: 10px;
  color: #a1a1a1;
`;

const Link = styled.a`
  text-decoration: underline;
  transition: 0.6s;
  color: #a1a1a1;

  &:hover {
    color: #f0c015;
  }
`;
