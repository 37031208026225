import React, { memo, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { desktop, tablet } from 'services/ui';
import fontService, { FontWeight } from 'services/FontService';

import { ITab } from 'atoms';
import { LessonDescription } from 'molecules/LessonDescription';
import { LessonJournalContainer } from 'molecules/LessonJournal/LessonJournalContainer';
import { Tabs, TasksTabs } from 'molecules';
import { LessonAttachments, TaskResults } from 'organisms';
import { Colors, UserRole } from 'context';
import { AttachmentType, LessonType } from 'types';
import { PATHS } from 'router/config';
import { isNow } from 'services/DateService';

export interface ILessonComponentProps {
  currentLesson?: LessonType | null;
  pathToDescription: string;
  pathToTasks: string;
  tabs: ITab[];
  hasLessonsToFinish: boolean;
  activeRole: UserRole;
  isFree: boolean;
}

export const LessonComponent = memo(
  ({
    currentLesson,
    tabs,
    pathToTasks,
    pathToDescription,
    hasLessonsToFinish,
    activeRole,
    isFree,
  }: ILessonComponentProps) => {
    const { t } = useTranslation();
    const isNowOrEnded = useMemo(() => {
      if (currentLesson) {
        return currentLesson?.isEnded || isNow(currentLesson?.date);
      }
      return false;
    }, [currentLesson]);

    const showStudentAttachments = useMemo(() => {
      if (activeRole === UserRole.STUDENT) {
        return isNowOrEnded;
      }
      return activeRole === UserRole.METHODIST;
    }, [activeRole, isNowOrEnded]);

    const showTeacherTabs = useMemo(
      () => activeRole === UserRole.TEACHER && isNowOrEnded,
      [activeRole, isNowOrEnded],
    );
    return (
      <LessonWrapper>
        <LessonName>
          {currentLesson?.index}. {currentLesson?.name}
        </LessonName>
        <TabsStyled tabs={tabs} />
        <Separator />
        <ContentWrapper>
          <Routes>
            <Route path="/" element={<Navigate to={pathToDescription} replace />} />
            <Route
              path={PATHS.lessonDescription}
              element={
                <LessonDescription
                  key={currentLesson?.id}
                  videoSrc={currentLesson?.video}
                  description={currentLesson?.lmsDescription || `${t('lesson.noDescription')}`}
                  time={currentLesson?.time || ''}
                  isFree={isFree}
                />
              }
            />
            <Route
              path={PATHS.lessonStudentAttachments}
              element={
                <LessonAttachments
                  lessonId={currentLesson?.id || ''}
                  type={AttachmentType.student}
                  showAttachments={showStudentAttachments}
                />
              }
            />
            <Route
              path={PATHS.lessonTeacherAttachments}
              element={
                <LessonAttachments
                  lessonId={currentLesson?.id || ''}
                  type={AttachmentType.teacher}
                  showAttachments
                />
              }
            />
            <Route
              path={PATHS.lessonJournal}
              element={
                <LessonJournalContainer
                  isFinished={Boolean(currentLesson?.finished)}
                  comment={currentLesson?.comment || ''}
                  isEnded={Boolean(currentLesson?.isEnded)}
                  hasLessonsToFinish={hasLessonsToFinish}
                  showJournal={showTeacherTabs}
                />
              }
            />
            {currentLesson && (
              <>
                <Route
                  path={`${PATHS.lessonTasks}/*`}
                  element={
                    <TasksTabs
                      currentLesson={currentLesson}
                      pathToTasks={pathToTasks}
                      showTasks={showTeacherTabs}
                    />
                  }
                />
                <Route
                  path={PATHS.lessonResults}
                  element={<TaskResults lessonsId={currentLesson.id} activeRole={activeRole} />}
                />
              </>
            )}
          </Routes>
        </ContentWrapper>
      </LessonWrapper>
    );
  },
);

const LessonWrapper = styled.div`
  width: 100%;

  ${desktop} {
    width: 700px;
  }
`;

const LessonName = styled.p`
  margin: 0;
  ${fontService.getFont({ size: 18, lineHeight: 133, weight: FontWeight.bold })}

  ${tablet} {
    ${fontService.getFont({ size: 24, lineHeight: 150, weight: FontWeight.bold })}
  }

  ${desktop} {
    ${fontService.getFont({ size: 32, lineHeight: 138, weight: FontWeight.bold })}
  }
`;

const TabsStyled = styled(Tabs)`
  margin-top: 16px;
`;

const Separator = styled.div`
  height: 1px;
  background-color: ${Colors.system_gray};
`;

const ContentWrapper = styled.div`
  margin-top: 20px;

  ${tablet} {
    margin-top: 24px;
  }

  ${desktop} {
    margin-top: 36px;
  }
`;
