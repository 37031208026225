import React, { memo, useEffect, useMemo, useState } from 'react';
import { useReactiveVar } from '@apollo/client';

import { authState, useGetLesson, useGoToErrorPage, useLessonTabs } from 'core';
import { isNow } from 'services/DateService';
import { JournalPreloader } from 'atoms';
import { UserRole } from 'context';
import { LessonComponent } from './Lesson';

interface ILessonProps {
  groupId?: string;
  currentLessonId: string;
  hasLessonsToFinish: boolean;
  isFree: boolean;
}

export const Lesson = memo(
  ({ groupId, currentLessonId, hasLessonsToFinish, isFree }: ILessonProps) => {
    const { active_role } = useReactiveVar(authState);
    const [userRole] = useState(active_role);

    const goToErrorPage = useGoToErrorPage();

    const { currentLesson, loading, error } = useGetLesson(currentLessonId);

    const dateIsNow = useMemo(() => {
      if (currentLesson?.date) {
        return isNow(currentLesson.date);
      }
    }, [currentLesson?.date]);

    const { pathToDescription, pathToTasks, tabs } = useLessonTabs({
      groupId,
      lessonId: currentLessonId,
      userRole,
      currentLesson,
      dateIsNow,
    });

    useEffect(() => {
      if (error) {
        console.log({ lessonError: error });
        goToErrorPage();
      }
    }, [error, goToErrorPage]);

    return loading ? (
      <JournalPreloader />
    ) : (
      <LessonComponent
        currentLesson={currentLesson}
        tabs={tabs}
        pathToDescription={pathToDescription}
        pathToTasks={pathToTasks}
        hasLessonsToFinish={hasLessonsToFinish}
        activeRole={active_role as UserRole}
        isFree={isFree}
      />
    );
  },
);
