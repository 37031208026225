import React, { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import fontService, { FontWeight, TextType } from 'services/FontService';
import { desktop, tablet } from 'services/ui';
import { Colors } from 'context';
import { CourseStatus } from './CourseStatus';

export interface ICourseCard {
  id: string;
  courseId: string;
  courseName: string;
  groupName: string;
  studentsCount: number;
  courseImageURL: string;
  activeLessonNumber: number;
  lessonsCount: number;
  nextLesson: string;
  educationForm: string;
  statusFinished: boolean;
}

export const CourseCard = memo(
  ({
    courseName,
    groupName,
    studentsCount,
    activeLessonNumber,
    courseImageURL,
    lessonsCount,
    nextLesson,
    statusFinished,
    educationForm,
  }: ICourseCard) => {
    const { t } = useTranslation();
    return (
      <Wrapper>
        <CourseStatus
          statusFinished={statusFinished}
          educationForm={educationForm}
          isFree={courseName.toLowerCase().includes('free'.toLowerCase())}
        />
        <CourseName>{courseName}</CourseName>
        <InfoContainer>
          <Info>
            {t('courseInfo.group')} <InfoValue>{groupName}</InfoValue>
          </Info>
          <Info>
            {t('courseInfo.amount')}{' '}
            <InfoValue>
              {studentsCount} {t('courseInfo.attendance')}
            </InfoValue>
          </Info>
          <Info>
            {t('courseInfo.lessons')}{' '}
            <InfoValue>
              {activeLessonNumber}/{lessonsCount}
            </InfoValue>
          </Info>
          {!statusFinished && (
            <InfoNextLesson>
              {t('courseInfo.nextLesson')}
              <InfoValue>{nextLesson}</InfoValue>
            </InfoNextLesson>
          )}
        </InfoContainer>
        <CourseImage src={courseImageURL} alt={t('courseInfo.courseImageAlt')} />
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  padding: 24px 20px 128px;
  border-radius: 16px;
  box-sizing: border-box;
  background-color: ${Colors.white};
  border: 1px solid ${Colors.system_gray};
  cursor: pointer;
  position: relative;
  overflow: hidden;
  &:hover {
    z-index: 10;
    transition: 0.5s;
    filter: drop-shadow(0px 40px 80px rgba(192, 198, 203, 0.8));
  }

  ${tablet} {
    height: 240px;
    padding: 32px 32px;
    border-radius: 24px;
  }

  ${desktop} {
    width: 1110px;
    height: 313px;
    padding: 40px 0 47px 48px;
  }
`;

const CourseName = styled.h1`
  ${fontService.h3(133)}
  margin: 12px 0 24px 0;
  max-width: 80%;

  ${tablet} {
    ${fontService.h2(125)}
    margin: 16px 0 24px 0;
    max-width: 466px;
  }

  ${desktop} {
    ${fontService.h1()}
    max-width: 800px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  row-gap: 10px;
  z-index: 2;

  ${tablet} {
    flex-wrap: nowrap;
    position: absolute;
    bottom: 32px;
  }

  ${desktop} {
    bottom: 47px;
  }
`;

const Info = styled.div`
  margin-right: 24px;
  ${fontService.text({ type: TextType.extraSmall })};

  &:last-child {
    margin: 0;
  }
`;

const InfoNextLesson = styled(Info)`
  position: absolute;
  bottom: -50px;

  ${tablet} {
    position: static;
  }
`;

const InfoValue = styled.div`
  margin-top: 4px;
  ${fontService.text({ type: TextType.normal, weight: FontWeight.medium })};
`;

const CourseImage = styled.img`
  position: absolute;
  right: -34px;
  bottom: -80px;
  width: 270px;
  height: 210px;
  object-fit: contain;
  object-position: right;

  ${tablet} {
    right: -58px;
    bottom: -112px;
    width: 340px;
    height: 295px;
  }

  ${desktop} {
    bottom: -135px;
    width: 580px;
    height: 380px;
  }
`;
