import React, { memo } from 'react';
import { useReactiveVar } from '@apollo/client';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import fontService, { FontWeight, TextType } from 'services/FontService';
import { desktop, tablet } from 'services/ui';
import { Button, VideoPlayer } from 'atoms';
import { VideoUpload } from 'molecules';
import { authState } from 'core';
import { UserRole } from 'context';

export interface ILessonDescription {
  description: string;
  time: string;
  videoSrc?: string | null;
  isFree: boolean;
}

export const LessonDescription = memo(
  ({ description, time, videoSrc, isFree }: ILessonDescription) => {
    const { t } = useTranslation();
    const { active_role } = useReactiveVar(authState);
    return (
      <>
        {active_role === UserRole.TEACHER && !videoSrc && <VideoUpload />}
        {videoSrc && <VideoPlayer src={videoSrc} active_role={active_role} />}
        <LessonDescriptionHeader>{t('lesson.description')}</LessonDescriptionHeader>
        <LessonDescriptionText dangerouslySetInnerHTML={{ __html: description }} />
        {!isFree && <LessonTimeHeader>{t('lesson.dateTime')} </LessonTimeHeader>}
        {!isFree && <LessonTimeText>{time}</LessonTimeText>}
        {isFree && (
          <ButtonContent>
            <Button
              variant="primary"
              onClick={() =>
                window.open(
                  'https://learnit.com.pl/courses-discount/devops-online',
                  '_blank',
                  'noopener, noreferrer',
                )
              }>
              👉 Get kurs
            </Button>
          </ButtonContent>
        )}
      </>
    );
  },
);

const LessonDescriptionHeader = styled.h3`
  ${fontService.getFont({ size: 18, lineHeight: 133, weight: FontWeight.bold })}
  margin-bottom: 16px;

  ${tablet} {
    ${fontService.h3()}
  }

  &:not(:first-child) {
    margin-top: 24px;

    ${tablet} {
      margin-top: 40px;
    }

    ${desktop} {
      margin-top: 60px;
    }
  }
`;

const LessonDescriptionText = styled.div`
  ${fontService.text({ type: TextType.normal })}
`;

const LessonTimeHeader = styled.h3`
  ${fontService.getFont({ size: 18, lineHeight: 133, weight: FontWeight.bold })}
  margin-top: 24px;
  margin-bottom: 8px;

  ${tablet} {
    ${fontService.h3()}
    margin-top: 40px;
    margin-bottom: 16px;
  }

  ${desktop} {
    margin-top: 60px;
  }
`;

const LessonTimeText = styled.div`
  ${fontService.text({ size: 16, weight: FontWeight.regular })};

  ${tablet} {
    ${fontService.text({ weight: FontWeight.regular })};
  }
`;

const ButtonContent = styled.div`
  width: fit-content;
  width: 250px;
  margin-top: 30px;
`;
