import React, { memo } from 'react';
import styled from 'styled-components';

import { Icon, IconNames } from 'atoms';

import fontService, { FontWeight, TextType } from 'services/FontService';
import { desktop, tablet } from 'services/ui';
import { Colors } from 'context';
import i18n from '../../../i18n';

export interface ICourseStatus {
  statusFinished: boolean;
  educationForm: string;
  isFree: boolean;
}

export const getStatusOfCourseTranslated = (status: StatusOfCourse): string =>
  ({
    [StatusOfCourse.inProgress]: i18n.t('courseInfo.status.inProgress'),
    [StatusOfCourse.finished]: i18n.t('courseInfo.status.finished'),
  }[status]);

export const getFormOfEducationTranslated = (form: FormOfEducation): string =>
  ({
    [FormOfEducation.online]: i18n.t('courseInfo.educationForm.online'),
    [FormOfEducation.offline]: i18n.t('courseInfo.educationForm.offline'),
  }[form]);

enum StatusOfCourse {
  inProgress,
  finished,
}

enum FormOfEducation {
  online,
  offline,
}

export const CourseStatus = memo(({ statusFinished, educationForm, isFree }: ICourseStatus) => (
  <Wrapper>
    {educationForm
      ? getFormOfEducationTranslated(FormOfEducation[educationForm as keyof typeof FormOfEducation])
      : getFormOfEducationTranslated(FormOfEducation.online)}
    ,{' '}
    <Status statusFinished={statusFinished}>
      {' '}
      <IconStyled name={statusFinished ? IconNames.TimerEnd : IconNames.TimerInProcess} />{' '}
      {statusFinished
        ? getStatusOfCourseTranslated(StatusOfCourse.finished)
        : getStatusOfCourseTranslated(StatusOfCourse.inProgress)}
    </Status>
    {isFree && <StatusFree>FREE</StatusFree>}
  </Wrapper>
));

const Wrapper = styled.div`
  ${fontService.text({ type: TextType.extraSmall, weight: FontWeight.bold })};
  color: ${Colors.gray};
  letter-spacing: 0.08em;
  text-transform: uppercase;

  ${tablet} {
    ${fontService.text({ type: TextType.small, weight: FontWeight.bold })};
  }

  ${desktop} {
    ${fontService.text({ type: TextType.normal, weight: FontWeight.bold })};
  }
`;

const IconStyled = styled((props) => <Icon {...props} />)`
  display: inline-block;
  width: 12px;
  height: 12px;
`;

const Status = styled.span<{ statusFinished: boolean }>`
  color: ${({ statusFinished }) => (statusFinished ? Colors.black : Colors.marker_green)};
`;

const StatusFree = styled.span`
  width: fit-content;
  color: ${Colors.black};
  letter-spacing: 0.08em;
  text-transform: uppercase;
  background: ${Colors.main};
  padding: 8px 20px;
  border-radius: 5px;
  margin-left: 20px;
`;
